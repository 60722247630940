import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./Gallery.css";
import Img1 from "./img/1.png";
import Img2 from "./img/2.png";
import Img3 from "./img/3.png";
import Img4 from "./img/4.png";
import Img5 from "./img/5.png";
import Img6 from "./img/6.png";
const Galery = () => {
  let data = [
    {
      id: 1,
      alt: "",
      imgSrc: Img1,
    },
    {
      id: 2,
      imgSrc: Img2,
    },
    {
      id: 3,
      imgSrc: Img3,
    },
    {
      id: 4,
      imgSrc: Img4,
    },
    {
      id: 5,
      imgSrc: Img5,
    },
    {
      id: 6,
      imgSrc: Img6,
    },
  ];

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} alt="" />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="gallery-container">
        {data.map((item, index) => {
          return (
            <div
              className="gallery-item"
              key={index}
              onClick={() => getImg(item.imgSrc)}
            >
              <img src={item.imgSrc} alt={item.alt} style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
      ;
    </>
  );
};

export default Galery;
