import { styled } from "@mui/system";
import styledComponents from "styled-components";

export const HeaderContainer = styled("div")`
  background-color: #344050;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styledComponents.img`
  height: 40px;
  width: auto;
  margin-right: 55%;
`;

export const Menu = styledComponents.div`
  display: flex;
  gap: 16px;
`;

export const MenuItem = styledComponents.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export const SocialMediaContainer = styledComponents.div`
  display: flex;
  gap: 16px;
  margin-left: 57%;
  margin-right: 200px;
`;

export const SocialMediaIcon = styledComponents.a`
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;
