import { AppBar, Toolbar } from "@mui/material";
import { HeaderContainer, Menu, MenuItem } from "./footerStyle";

function Footer() {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#344050" }}>
      <Toolbar>
        <HeaderContainer>
          <Menu>
            <MenuItem href="https://fehmer.tech/impressum.html">
              Impresum
            </MenuItem>
            <MenuItem href="https://fehmer.tech/datenschutz.html">
              Datenschutz
            </MenuItem>
          </Menu>
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
