import { styled } from "@mui/system";
import styledComponents from "styled-components";

export const HeaderContainer = styled("div")`
  background-color: #344050;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 8px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 12px;
  }
`;

export const Logo = styledComponents.img`
  height: 40px;
  width: auto;
  margin-right: 55%;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 16px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-right: 30%;
  }
`;

export const Menu = styledComponents.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    gap: 12px;
  }
`;

export const MenuItem = styledComponents.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const SocialMediaContainer = styledComponents.div`
  display: flex;
  gap: 16px;
  margin-left: 57%;
  margin-right: 200px;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 30%;
    margin-right: 0;
  }
`;

export const SocialMediaIcon = styledComponents.a`
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;
