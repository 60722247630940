import { AppBar, Toolbar } from "@mui/material";
import {
  HeaderContainer,
  Logo,
  Menu,
  MenuItem,
  SocialMediaContainer,
  SocialMediaIcon,
} from "./headerStyles";
import logoImage from "../img/fehmer.tech.png"; // Pfad zum Logo-Bild
import { Twitter, GitHub, Description, Home } from "@mui/icons-material"; // Importieren der MUI Icons

function Header() {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#344050" }}>
      <Toolbar>
        <HeaderContainer>
          <Logo src={logoImage} alt="Logo" />
          <Menu>
            <MenuItem href="https://fehmer.tech">Home</MenuItem>
            <MenuItem href="https://blog.fehmer.tech/">Blog</MenuItem>
            <MenuItem href="https://linktree.fehmer.tech/">Linktree</MenuItem>
            <MenuItem href="/linktree" to="/about">
              Über
            </MenuItem>
          </Menu>
          <SocialMediaContainer>
            <SocialMediaIcon href="https://twitter.com/yourtwitterprofile">
              <Twitter />
            </SocialMediaIcon>
            <SocialMediaIcon href="https://github.com/yourgithubprofile">
              <GitHub />
            </SocialMediaIcon>
            <SocialMediaIcon href="https://yourblogurl.com">
              <Description />
            </SocialMediaIcon>
            <SocialMediaIcon href="https://yourhomepage.com">
              <Home />
            </SocialMediaIcon>
          </SocialMediaContainer>
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
