import "./App.css";
import Galery from "./Gallery";
import Header from "./header/header";
import Footer from "./footer/footer";
function App() {
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center" }}>AI Image Galery</h1>
      <Galery />
      <Footer />
    </>
  );
}

export default App;
